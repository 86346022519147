import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DismountContainerModalComponent } from './components/dismount-container-modal/dismount-container-modal.component';
import { DismountDummyPalletModalComponent } from './components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import {
  ArticleDescription,
  LabelPrintResult,
  PileTurnerScanContainerResponse,
  PileTurnerWorkCenter,
  PrimaryMaterialMounted
} from 'chronos-core-client';
import { DismountingPalletInformationMapper, LoadingNotificationService, LogService } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { PalletListModalComponent } from './components/pallet-list-modal/pallet-list-modal.component';
import { TreeNode } from 'primeng/api';
import { MountedPalletItemType, WorkCenterDetailsView } from '@pt/modules/pile-turner/pile-turner.model';
import * as R from 'ramda';
import { notificationTopic } from '@pt/shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pt-work-center-details',
  templateUrl: './work-center-details.component.html',
  styleUrls: ['./work-center-details.component.scss']
})
export class WorkCenterDetailsComponent {
  @Input() public set workCenter(workCenter: PileTurnerWorkCenter) {
    if (this.selectedWorkCenter?.workCenterId !== workCenter?.workCenterId) {
      this.selectedWorkCenter = workCenter;
      this.updateMountedPalletData();
    } else {
      this.selectedWorkCenter = workCenter;
    }
  }

  @Input() public set pallet(pallet: PileTurnerScanContainerResponse) {
    if (pallet?.directMounting && pallet?.allowedWorkCenterIds.length === 1) {
      this.mountPalletAutomatically(pallet);
    } else {
      this.setInitialView(pallet);
    }
  }

  @Output() public selectedPalletChanged = new EventEmitter<PileTurnerScanContainerResponse | null>();

  public selectedPallet?: PileTurnerScanContainerResponse;
  public selectedWorkCenter?: PileTurnerWorkCenter;
  public viewOptions = [
    { value: WorkCenterDetailsView.OrderView, label: 'PILE_TURNER.ORDER_VIEW' },
    { value: WorkCenterDetailsView.MountedPalletsView, label: 'PILE_TURNER.MOUNTED_PALLET_LIST_VIEW' }
  ];
  public selectedView = WorkCenterDetailsView.OrderView;
  public mountedPalletData: TreeNode[] = [];

  public readonly VIEW = WorkCenterDetailsView;
  public readonly ITEM_TYPE = MountedPalletItemType;

  constructor(
    private workCenterDetailsService: WorkCenterDetailsService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public mountPallet(whiteWaste: number): void {
    if (this.selectedWorkCenter && this.selectedPallet) {
      LoadingNotificationService.publish(notificationTopic.mountPalletAction, true);
      this.workCenterDetailsService
        .mountPallet(this.selectedWorkCenter.workCenterId, this.selectedPallet, { value: whiteWaste, unitId: '' })
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(notificationTopic.mountPalletAction, false);
          })
        )
        .subscribe(() => {
          this.unselectPallet();
          LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
        });
    } else {
      throw new Error(
        `WorkCenterDetailsComponent.mountPallet: selectedWorkCenter = ${typeof this.selectedWorkCenter}, selectedPallet = ${typeof this
          .selectedPallet}`
      );
    }
  }

  public unselectPallet(): void {
    this.selectedPalletChanged.emit(null);
  }

  public openPalletList(): void {
    if (this.selectedWorkCenter) {
      const workCenter = this.selectedWorkCenter.workCenter;
      const quantityChangeFlag = this.selectedWorkCenter.allowQuantityChangeInDismount;
      const classification = this.translateService.instant(
        `PARAMETER_FIELD.${this.selectedWorkCenter.nextOrder.article.classification.toUpperCase()}`
      );
      const titleCaseClassification = this.toTitleCase(classification);

      this.dialogService.open(PalletListModalComponent, {
        header: `${workCenter.externalWorkCenterId} ${workCenter.workCenterName} ${titleCaseClassification} list`,
        data: { workCenter, quantityChangeFlag }
      });
    } else {
      throw new Error(`WorkCenterDetailsComponent.openPalletList: selectedWorkCenter = ${typeof this.selectedWorkCenter}`);
    }
  }

  private toTitleCase(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  public onDismountClick(): void {
    if (this.selectedWorkCenter) {
      const workCenterId = this.selectedWorkCenter.workCenterId;
      this.workCenterDetailsService.getNextDismountableContainer(workCenterId).subscribe((mountedContainer: PrimaryMaterialMounted) => {
        if (mountedContainer.container?.isVirtualContainer) {
          this.openDummyPalletDismountingModal(workCenterId, mountedContainer, mountedContainer.article);
        } else {
          if (this.selectedWorkCenter?.article) {
            this.openPalletDismountingModal(mountedContainer, mountedContainer.article);
          } else {
            throw new Error(`WorkCenterDetailsComponent.onDismountClick: article = ${typeof this.selectedWorkCenter?.article}`);
          }
        }
      });
    } else {
      throw new Error(`WorkCenterDetailsComponent.onDismountClick: selectedWorkCenter = ${typeof this.selectedWorkCenter}`);
    }
  }

  public onPalletDismountClick(mountedMaterial: PrimaryMaterialMounted, rowNode: TreeNode): void {
    if (mountedMaterial.container?.isVirtualContainer) {
      if (this.selectedWorkCenter) {
        this.openDummyPalletDismountingModal(this.selectedWorkCenter.workCenterId, mountedMaterial, mountedMaterial.article);
      } else {
        throw new Error(`WorkCenterDetailsComponent.onPalletDismountClick: selectedWorkCenter = ${typeof this.selectedWorkCenter}`);
      }
    } else {
      this.openPalletDismountingModal(mountedMaterial, rowNode.parent?.data.article);
    }
  }

  public updateMountedPalletData(): void {
    if (this.selectedView === WorkCenterDetailsView.MountedPalletsView && this.selectedWorkCenter) {
      this.workCenterDetailsService
        .getMountedMaterialsTree(this.selectedWorkCenter?.workCenterId)
        .subscribe((data) => (this.mountedPalletData = data));
    }
  }

  private openPalletDismountingModal(material: PrimaryMaterialMounted, article: ArticleDescription) {
    if (this.selectedWorkCenter) {
      this.dialogService
        .open(DismountContainerModalComponent, {
          header: this.translateService.instant('DISMOUNT_CONTAINER_FORM.DISMOUNTING'),
          data: {
            workCenter: this.selectedWorkCenter,
            containerId: material.container?.containerId,
            article,
            dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(material, article),
            allowQuantityChangeInDismount: this.selectedWorkCenter.allowQuantityChangeInDismount
          }
        })
        .onClose.subscribe(() => {
          this.updateMountedPalletData();
        });
    } else {
      throw new Error(`WorkCenterDetailsComponent.openPalletDismountingModal: selectedWorkCenter = ${typeof this.selectedWorkCenter}`);
    }
  }

  private openDummyPalletDismountingModal(workCenterId: number, material: PrimaryMaterialMounted, article: ArticleDescription): void {
    if (material.container) {
      this.dialogService
        .open(DismountDummyPalletModalComponent, {
          header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET'),
          data: {
            workCenterId,
            containerId: material.container.containerId,
            remainingQuantity: material.container?.bomGoodQuantity.value,
            isLabelPrinted: material.container.labelPrintResult === LabelPrintResult.DONE,
            dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(material, article)
          }
        })
        .onClose.subscribe(() => {
          this.updateMountedPalletData();
        });
    } else {
      throw new Error(`WorkCenterDetailsComponent.dismountDummyPallet: container = ${typeof material.container}`);
    }
  }

  private setInitialView(pallet: PileTurnerScanContainerResponse): void {
    this.selectedView = WorkCenterDetailsView.OrderView;
    this.selectedPallet = pallet;
  }

  private mountPalletAutomatically(pallet: PileTurnerScanContainerResponse): void {
    const workCenterId = R.head(pallet.allowedWorkCenterIds)?.workCenterId;
    const emptyQuantity = { value: 0, unitId: '' };
    if (workCenterId) {
      this.workCenterDetailsService.mountPallet(workCenterId, pallet, emptyQuantity).subscribe(
        () => {
          this.unselectPallet();
          LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
        },
        () => {
          this.setInitialView(pallet);
        }
      );
    } else {
      throw new Error(`WorkCenterDetailsComponent.mountPalletAutomatically: workCenterId = ${typeof workCenterId}`);
    }
  }
}
